import React, { useEffect, useState, useCallback } from 'react';
import config from '../config.js'

interface Props {
    onSuccess: (gauth: gapi.auth2.GoogleAuth) => void,
}

// To work around a bug in useRef that causes it to not re-render your component
// when the ref changes (from initial null to the actual element)
//
// Based on https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
function useRefWithState<T>(value: T) {
    const [current, setCurrent] = useState(value)
    const ref = useCallback((node: T) => {
        setCurrent(node);
    }, []);

    return [ref, current]
}
 
function Login({ onSuccess }: Props) {
    const [loginRef, current] = useRefWithState(null)

    useEffect(() => {
        if (current === null) { return }
        window.gapi.load('auth2:signin2', () => {
            window.gapi.auth2.init({
                client_id: config.GAPI_CLIENT_ID,
                hosted_domain: 'gauntlet.network'
            }).then((gauth) => {
                window.gapi.signin2.render(current, {
                    'scope': 'profile email',
                    'width': 250,
                    'height': 50,
                    'longtitle': false,
                    'theme': 'dark',
                    'onsuccess': () => {
                        onSuccess(gauth)
                    },
                    'onfailure': () => {
                        console.log("Sign-in failed")
                    }
                })
            })
        })
    }, [onSuccess, current])

    return (
        <div className="Login" ref={loginRef}/>
    )
}

export default Login; 