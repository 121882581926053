import React, { useState, useContext } from 'react';
import Login from "../login/login";

interface User {
    currUser: gapi.auth2.GoogleUser | null
    setCurrUser: (user: gapi.auth2.GoogleUser | null) => void,
};

export const UserContext: React.Context<User> = React.createContext({
    currUser: null,
    setCurrUser: (user: gapi.auth2.GoogleUser | null) => null,
} as User);

export function ConnectedLogin() {
    const {setCurrUser} = useContext(UserContext)

    const loginUser = (user: gapi.auth2.GoogleUser) => {
        setCurrUser(user);
    };

    return (
        <Login onSuccess={(gauth) => {
            loginUser(gauth.currentUser.get())
        }}/>
    )
}

const WithUser: React.FC<{}> = ({children}) => {
    const [currUser, setCurrUser] = useState<gapi.auth2.GoogleUser | null>(null);

    return (
        <UserContext.Provider value = {{currUser, setCurrUser}}>
            {children}
        </UserContext.Provider>
    )
}

export default WithUser;
