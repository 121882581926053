import React, { useContext } from 'react';
import { UserContext } from "../withUser/withUser";
import config from '../config.js'
import "./logout.css"

export function Logout() {
    const { setCurrUser } = useContext(UserContext)

    const logoutUser = ((value: gapi.auth2.GoogleUser | null) => {
        window.gapi.auth2.init({ 
            client_id: config.GAPI_CLIENT_ID, 
            hosted_domain: 'gauntlet.network' 
        }).then((gauth) => {
            return gauth.signOut();
        }).then(() => {
            setCurrUser(value);
        });
    })

    return (
        <div>
            <button className="myButton" onClick={() => logoutUser(null)}>Logout</button>
        </div>
    )
}