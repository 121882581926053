import React, { useContext } from 'react';
import './App.css';
import { Logout } from "../logout/logout"
import WithUser, { UserContext, ConnectedLogin } from "../withUser/withUser";

const Dashboard = () => {
  return (
    <div>
      <Logout/>
      {/*<div>TODO:Display Notebooks</div>*/}
    </div>
  )
}

const DashboardPage = () => {
  const {currUser} = useContext(UserContext)

  if (currUser) {
    return <Dashboard/>
  } else {
      return <ConnectedLogin/>
  }
}

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <WithUser>
          <DashboardPage/>
        </WithUser>
      </header>
    </div>
  );
}

export default App;